export const env = {
  DEV: {
    API_URL: `https://api.lo.nftydoor.com/dev`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev`,
  },
  DANTE: {
    API_URL: `https://86j81z6xx6.execute-api.us-east-1.amazonaws.com/dev-dante`,
    API_ADMIN_URL: `https://in4gtzyli7.execute-api.us-east-1.amazonaws.com/dev-dante`,
  },
  FRAN_CAJAL: {
    API_URL: `https://si0ylryix7.execute-api.us-east-1.amazonaws.com/dev-fran`,
    API_ADMIN_URL: `https://qqlk79knaj.execute-api.us-east-1.amazonaws.com/dev-fran`,
  },
  ROQUE: {
    API_URL: `https://iz0oucn123.execute-api.us-east-1.amazonaws.com/dev-roque`,
    API_ADMIN_URL: `https://izqn0qn7md.execute-api.us-east-1.amazonaws.com/dev-roque`,
  },
  LEO: {
    API_URL: `https://8wsua8x475.execute-api.us-east-1.amazonaws.com/dev-leo`,
    API_ADMIN_URL: `https://aq11qbtix6.execute-api.us-east-1.amazonaws.com/dev-leo`,
  },
  LU: {
    API_URL: `https://3e4txdssgg.execute-api.us-east-1.amazonaws.com/dev-lu`,
    API_ADMIN_URL: `https://23qtqb2efa.execute-api.us-east-1.amazonaws.com/dev-lu`,
  },
  JUSTO: {
    API_URL: `https://ewzqlp2x1a.execute-api.us-east-1.amazonaws.com/dev-justo`,
    API_ADMIN_URL: `https://1ic61g1ef7.execute-api.us-east-1.amazonaws.com/dev-justo`,
  },
  LEANDRO: {
    API_URL: `https://u1g8g2bhqh.execute-api.us-east-1.amazonaws.com/dev-leandro`,
    API_ADMIN_URL: `https://opgepj5xo8.execute-api.us-east-1.amazonaws.com/dev-leandro`,
  },
  FRANCO: {
    API_URL: `https://phl8572j63.execute-api.us-east-1.amazonaws.com/dev-franco`,
    API_ADMIN_URL: `https://wfflrcfa0f.execute-api.us-east-1.amazonaws.com/dev-franco`,
  },
  JOAQUIN: {
    API_URL: `https://00lov29pj4.execute-api.us-east-1.amazonaws.com/dev-joaquin`,
    API_ADMIN_URL: `https://jwd6kvwg3l.execute-api.us-east-1.amazonaws.com/dev-joaquin`,
  },
  WALY: {
    API_URL: `https://2wf4eq743k.execute-api.us-east-1.amazonaws.com/dev-waly`,
    API_ADMIN_URL: `https://0ho2g81nue.execute-api.us-east-1.amazonaws.com/dev-waly`,
  },
  ALEJO: {
    API_URL: `https://kccn4cqdcl.execute-api.us-east-1.amazonaws.com/dev-alejo`,
    API_ADMIN_URL: `https://inl3kmgix0.execute-api.us-east-1.amazonaws.com/dev-alejo`,
  },
  SIMON: {
    API_URL: `https://5slz5omj31.execute-api.us-east-1.amazonaws.com/dev-simon`,
    API_ADMIN_URL: `https://4n17fcn6cd.execute-api.us-east-1.amazonaws.com/dev-simon`,
  },
  NICOLAS: {
    API_URL: `https://5pbxqdd0lk.execute-api.us-east-1.amazonaws.com/dev-nicolas`,
    API_ADMIN_URL: `https://73op81fnf7.execute-api.us-east-1.amazonaws.com/dev-nicolas`,
  },
  ESTEBAN: {
    API_URL: `https://7iksp5i5ca.execute-api.us-east-1.amazonaws.com/dev-esteban`,
    API_ADMIN_URL: `https://k1kvbqdaqd.execute-api.us-east-1.amazonaws.com/dev-esteban`,
  },
  SHANE: {
    API_URL: `https://yb9wyg3fue.execute-api.us-east-1.amazonaws.com/dev-shane`,
    API_ADMIN_URL: `https://lyc0rmawjf.execute-api.us-east-1.amazonaws.com/dev-shane`,
  },
  JUANI: {
    API_URL: `https://qts9ijb9yi.execute-api.us-east-1.amazonaws.com/dev-juani`,
    API_ADMIN_URL: `https://16ufoeolme.execute-api.us-east-1.amazonaws.com/dev-juani`,
  },
  PABLOBA: {
    API_URL: `https://fcill65hv5.execute-api.us-east-1.amazonaws.com/dev-pabloba`,
    API_ADMIN_URL: `https://6fbbieyfdh.execute-api.us-east-1.amazonaws.com/dev-pabloba`,
  },
  JULIAN: {
    API_URL: `https://j1mi3bwth0.execute-api.us-east-1.amazonaws.com/dev-julian`,
    API_ADMIN_URL: `https://uthdkho2pe.execute-api.us-east-1.amazonaws.com/dev-julian`,
  },
  MELANY: {
    API_URL: `https://immo2xjuy9.execute-api.us-east-1.amazonaws.com/dev-melany`,
    API_ADMIN_URL: `https://c1dw35at12.execute-api.us-east-1.amazonaws.com/dev-melany`,
  },
  MATIAS: {
    API_URL: `https://xdlce3cp45.execute-api.us-east-1.amazonaws.com/dev-matias`,
    API_ADMIN_URL: `https://3bbwwqrpxg.execute-api.us-east-1.amazonaws.com/dev-matias`,
  },
  MAXI: {
    API_URL: `https://o9kxk8s7l3.execute-api.us-east-1.amazonaws.com/dev-maxi`,
    API_ADMIN_URL: `https://c6eb4b5u42.execute-api.us-east-1.amazonaws.com/dev-maxi`,
  },
  ARIEL: {
    API_URL: `https://wkz2gzw5j3.execute-api.us-east-1.amazonaws.com/dev-ariel`,
    API_ADMIN_URL: `https://576clewkhl.execute-api.us-east-1.amazonaws.com/dev-ariel`,
  },
  JULIO: {
    API_URL: `https://v8pa9wxy57.execute-api.us-east-1.amazonaws.com/dev-jc`,
    API_ADMIN_URL: `https://red4tujsc8.execute-api.us-east-1.amazonaws.com/dev-jc`,
  },
  LUIS: {
    API_URL: `https://h13bfv8kil.execute-api.us-east-1.amazonaws.com/dev-luis`,
    API_ADMIN_URL: `https://zjjc99x9u0.execute-api.us-east-1.amazonaws.com/dev-luis`,
  },
  NICOLASF: {
    API_URL: `https://z49insfbjc.execute-api.us-east-1.amazonaws.com/dev-nicolasf`,
    API_ADMIN_URL: `https://jlmkw8fl36.execute-api.us-east-1.amazonaws.com/dev-nicolasf`,
  },
  GABRIEL: {
    API_URL: `https://ljwyarkz37.execute-api.us-east-1.amazonaws.com/dev-gabriel`,
    API_ADMIN_URL: `https://h7u6tpzujd.execute-api.us-east-1.amazonaws.com/dev-gabriel`,
  },
  SANTI: {
    API_URL: `https://rzm1kevta5.execute-api.us-east-1.amazonaws.com/dev-santi`,
    API_ADMIN_URL: `https://udp0wuqop2.execute-api.us-east-1.amazonaws.com/dev-santi`,
  },
  PROD: {
    API_URL: `https://api.lo.nftydoor.com/prod`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/prod`,
  },
};

export const ENV = localStorage.getItem("currentEnvironment") ?? "DEV";

function getEnvVariable(key) {
  const isInProduction = process.env.REACT_APP_ENV === "prod";
  return env[isInProduction ? "PROD" : ENV][key];
}

export default getEnvVariable;
